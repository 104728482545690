var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('editor',{staticClass:"tiny",attrs:{"value":_vm.value,"api-key":_vm.mce_key,"disabled":_vm.disabled,"rules":_vm.rules,"init":{
            height: 400,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor table',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'save',
            ],
            toolbar:
                'formatselect bold italic backcolor \
                alignleft aligncenter alignright alignjustify \
                table tabledelete bullist numlist outdent indent removeformat',
            toolbar_mode: 'wrap',
            content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            invalid_elements: 'iframe,a,script',
            save_enablewhendirty : false,
            init_instance_callback: () => {
                _vm.editorInitialized()
            }
        }},on:{"input":($event) => _vm.handleSave($event)}}),(_vm.showSaveButton)?_c('section',{staticClass:"d-flex flex-row justify-end"},[(_vm.initialized)?_c('ButtonPrimary',{staticClass:"ml-auto poppins mt-1",attrs:{"label":'SAVE'},on:{"click":function($event){return _vm.$emit('setValue', _vm.val)}}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }